import React from 'react'

import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Hand from '../../../../../assets/img/handWhite.svg'
import Lock from '../../../../../assets/img/lockWhite.svg'
import Cloud from '../../../../../assets/img/cloudWhite.svg'
import Shield from '../../../../../assets/img/shieldWhite.svg'

import './cloud.scss'

export default () =>
    <StaticQuery
        query={graphql`
        query {
            CloudBased: file(relativePath: { eq: "industries/cloudbased.png" }) {
                childImageSharp {
                    fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                    }
                }
            }
           
        }
        `}
        render={data => (
            <section className="enterprisePage__cloud">
                <div className="container">
                    <div className="row">
                        <div className="col__md-50">
                            <h2>Cloud-Based Software Licensing</h2>
                            <p>Enable and enforce licensing for machines that do not have an active internet connection, for environments with restricted internet access, or limited ability to check the status of a license online.</p>
                            <ul>
                                <li><img src={Hand} alt="Hand icon" />Prevent Unlicensed usage of your software</li>
                                <li><img src={Lock} alt="Lock icon" />Protect your software IP</li>
                                <li><img src={Cloud} alt="Cloud icon" />Enforce your software license agreement with your end-users</li>
                                <li><img src={Shield} alt="Shield icon" />Cloud Based, Cross-Platform</li>
                            </ul>
                        </div>
                        <Img className="col__md-50" fluid={data.CloudBased.childImageSharp.fluid} alt="Cloud image" />
                    </div>
                </div>
            </section>
        )} />