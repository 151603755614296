import React from 'react'

import Layout from '../../../components/Layout'
import SEO from "../../../components/Seo"

import OfflineLicenseBlock from '../../../components/OfflineLicenseBlock'
import TrustedBlock from '../../../components/TrustedBlock'
import BottomBlock from '../../../components/BottomBlock'

import Cloud from '../../../components/Pages/Industries/Enterprise/Cloud'
import Intro from '../../../components/Pages/Industries/Enterprise/Intro'

import './enterprise.scss'

export default ({location}) =>
    <Layout className="page page--industry enterprisePage">
        <SEO
            title="LicenseSpring: Licensing for Enterprise and IT Applications"
            description="Complex licensing models where security and reliability are paramount for the success of your operations. Learn more or partner with us."
            path={location.pathname}
        />
        <Intro />
        <Cloud />
        <div className="container">
            <OfflineLicenseBlock />
        </div>
        <TrustedBlock />
        <BottomBlock pricingLink />
    </Layout>