import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Button from "../../../../Button"

import Shield from "../../../../../assets/img/shield.svg"

import "./intro.scss"

export default () => (
  <StaticQuery
    query={graphql`
      query {
        HeroImage: file(relativePath: { eq: "industries/enterprise.png" }) {
          childImageSharp {
            fluid(maxWidth: 4000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        OrganizationsMobile: file(
          relativePath: { eq: "industries/organizations_mobile.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        Organizations: file(
          relativePath: { eq: "industries/organizations.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => (
      <section className="enterprisePage__intro">
        <Img
          className="enterprisePage__heroImage"
          fluid={data.HeroImage.childImageSharp.fluid}
          alt="Hero image"
        />
        <div className="container">
          <h1>
            <span>
              Enterprise <br />
              Cloud-Based
            </span>{" "}
            Software Licensing
          </h1>
          <p>
            Flexible & customizable software entitlements to fit your
            organization's use-cases, whether it's licensing software that runs
            in a secure environment, to VM containers, to sophisticated
            account-based licensing terms.
          </p>
          <ul>
            <li>Offline Licensing & proxy server configuration</li>
            <li>Floating License Server + Cloud</li>
            <li>Account-Based Licensing</li>
            <li>Wrappers for apps previously thought un-licensable</li>
          </ul>
          <Button
            label="Open trial account"
            size="big"
            shadow
            color="blue"
            disclaimer
            to="/get-started/"
          />
          <div>
            <p>
              <img src={Shield} alt="Shield icon" /> Trusted by hundreds of
              organizations including:
            </p>
          </div>
          <Img
            className="organizationsLogos organizationsLogos--mobile"
            fluid={data.OrganizationsMobile.childImageSharp.fluid}
            alt="Organizations logos"
          />
          <Img
            className="organizationsLogos"
            fluid={data.Organizations.childImageSharp.fluid}
            alt="Organizations logos"
          />
        </div>
      </section>
    )}
  />
)
